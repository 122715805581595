import { Drawer, List, message } from "antd";
import storage from "@l/storage";
import { useNavigate } from "react-router-dom";
import { capitalFirstLetter } from "@/utils/capitalizeFirstLetter";
import styled from "styled-components";
import authState from "../../states/auth";
import { useQueryClient } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { Loader } from "@/pages/auth/styles/loader";
import { useState } from "react";
import client from "@/lib/axios-client";
import { determineRole } from "@/utils/determine-role";
interface IMobile {
  openMobile: boolean;
  onCloseMobile: () => void;
}

const StyledMobileNav = styled.div`
  margin-top: 56px;
  .m-nav-item-container {
    display: flex;
    align-items: center;
    gap: 8px;
  }
  .m-nav-item {
    font-size: 16px;
    font-weight: 700;
    color: #30345e;
  }
`;

const MobileNav = ({ openMobile, onCloseMobile }: IMobile) => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const isFranchise = storage.utilities.isFranchise();
  const isAccountManager = storage.utilities.isAccountManager();
  const isDealerDSA = storage.utilities.isDealerDsa();
  const setLogin = authState((state: IAuthState) => state.setLogin);
  const { t } = useTranslation("navigation");
  const [loading, setLoading] = useState(false);
  const isAssumedUser = storage.getAssumedUser();

  const menus = [
    {
      title: t("dashboard"),
      path: "/dashboard",
      new: false,
    },
    {
      title: t("applications"),
      path: "/applications",
      new: false,
    },
    {
      title: t("loan-calculator"),
      path: "/loan-calculator",
      new: false,
    },
    {
      title: t("prequalification"),
      path: "/prequalification",
      new: false,
    },
    {
      title: t("Settings"),
      path: "/settings",
      new: false,
    },
  ];

  const otherRoleMenus = [
    {
      title: t("dashboard"),
      path: "/dashboard",
      new: false,
    },
    {
      title: t("applications"),
      path: "/applications",
      new: false,
    },
    {
      title: t("inventory"),
      path: "/inventory",
      new: false,
    },
    {
      title: t("loan-calculator"),
      path: "/loan-calculator",
      new: false,
    },
    {
      title: t("prequalification"),
      path: "/prequalification",
      new: false,
    },
    // {
    //   title: "best deals",
    //   path: "/dealer/best-deals",
    //   new: true,
    // },
    {
      title: t("Settings"),
      path: "/settings",
      new: false,
    },
  ];

  const franchiseMenus = [
    {
      title: t("dashboard"),
      path: "/dealer/dashboard",
    },
    {
      title: t("applications"),
      path: "/dealer/applications",
    },
    {
      title: t("my-financing"),
      path: "/dealer/financing",
    },
    {
      title: "best deals",
      path: "/dealer/best-deals",
    },
    {
      title: t("inventory"),
      path: "/inventory",
    },
    {
      title: t("loan-calculator"),
      path: "/loan-calculator",
    },
    {
      title: t("prequalification"),
      path: "/prequalification",
    },
    {
      title: t("Settings"),
      path: "/settings",
    },
  ];

  let navMenus;

  if (isFranchise) {
    navMenus = franchiseMenus;
  } else if (isAccountManager || isDealerDSA) {
    navMenus = otherRoleMenus;
  } else {
    navMenus = menus;
  }

  const handleSignOut = () => {
    queryClient.clear();
    storage.clearAll();
    setLogin(false);
    navigate("/auth/sign-in", { replace: true });
  };

  const handleRevertUser = async () => {
    setLoading(true);
    try {
      const response: any = await client.post(`/v1/auth/revert-user`, {});
      if (response) {
        const parsedResponse = JSON.parse(response?.data);
        const token = parsedResponse?.token;
        const user = parsedResponse?.user;
        const roles = parsedResponse?.authority?.roles;
        const permissions = parsedResponse?.authority?.permissions;
        const country = parsedResponse?.user?.country;

        const role = determineRole(roles);

        if (role) storage.setRole(role);

        storage.setToken(token);
        storage.setUser(user);
        storage.setRoles(roles);
        storage.setPermissions(permissions);
        storage.setCountry(country);
        storage.clearAssumedUser();
        storage.clearProxyUserToken();

        navigate("/manage/users", { replace: true });
        setLoading(false);
        message.success("User role reverted succesfully");

        onCloseMobile();
      }
    } catch (error: any) {
      const errorResp = JSON.parse(error?.response?.data)?.message;
      message.error(errorResp);
      setLoading(false);
    }
  };

  return (
    <div>
      <Drawer
        placement="left"
        onClose={onCloseMobile}
        open={openMobile}
        closable={false}
        rootClassName="mobile-nav-drawers"
        maskStyle={{ padding: 0 }}
      >
        <StyledMobileNav>
          <List
            size="large"
            dataSource={navMenus}
            footer={
              <button onClick={handleSignOut} className="m-nav-item-container">
                <img
                  src="https://media.autochek.africa/file/publicAssets/Vector-Stroke-1.svg"
                  alt="logout-icon"
                />{" "}
                <div className="m-nav-item">{t("logout")}</div>
              </button>
            }
            renderItem={(item) => (
              <List.Item style={{ paddingLeft: "0px" }}>
                <button
                  className="m-nav-item flex items-center"
                  onClick={() => {
                    navigate(`${item?.path}`, { replace: true });
                    onCloseMobile();
                  }}
                >
                  {capitalFirstLetter(item?.title)}
                </button>
              </List.Item>
            )}
          />

          {isAssumedUser && (
            <button
              className="text-sm bg-[#ffb619] text-[#30345e] font-semibold rounded-[44px] min-w-[126px] w-auto h-[32px] px-3 mt-5"
              onClick={handleRevertUser}
            >
              {loading ? <Loader variant="secondary" /> : "REVERT USER"}
            </button>
          )}
        </StyledMobileNav>
      </Drawer>
    </div>
  );
};

export default MobileNav;
